<script setup lang="ts">
import { string, object } from 'yup';
import { useForm } from 'vee-validate';
import { ref, useI18n } from '#imports';

type Email = {
  email: string;
};

interface IProps {
  isMobile?: boolean;
}

interface Emits {
  (e: 'emailValue', value: string): void;
}

defineProps<IProps>();
const emit = defineEmits<Emits>();
const { t } = useI18n();

const emailPattern = /^$|[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/g;

const emailSchema = ref<any>(
  object({
    email: string().email(t('global.wfemail')).matches(emailPattern, t('global.wfemail')),
  }),
);

const { handleSubmit } = useForm<Email>({
  validationSchema: emailSchema,
});

const onSubmit = handleSubmit((values) => {
  emit('emailValue', values.email);
});
</script>
<template>
  <div class="container">
    <form @submit="onSubmit">
      <h1
        class="font-bold"
        :class="[isMobile ? 'text-base' : 'text-extra']"
      >
        {{ $t('csat.submittedfeedback') }}
      </h1>
      <p :class="[isMobile ? 'mt-20 text-small' : 'mt-30 font-bold text-medium']">{{ $t('csat.feedbacktypeemail') }}</p>

      <ApzInput
        name="email"
        :wrapper-class="!isMobile ? 'w-[60%] mt-10' : 'w-[100%] mt-10'"
        :placeholder="$t('bookingform.placeholderemail')"
      />
      <p class="w-[271px] text-gray-dark text-small mt-15 mb-20">
        {{ $t('csat.feedbackemailnote') }}
      </p>
      <div
        v-if="isMobile"
        class="fixed bottom-0 bg-white inset-x-0 shadow-sm px-15"
      >
        <ApzButton
          type="primary"
          class="my-15 w-full bg-gradient-to-r from-primary to-primary-dark"
          native-type="submit"
        >
          {{ $t('global.submit') }}
        </ApzButton>
      </div>

      <ApzButton
        v-else
        type="primary"
        native-type="submit"
        class="float-right mb-20 !px-[34px] !py-[16px] min-w-[127px]"
      >
        {{ $t('global.submit') }}
      </ApzButton>
    </form>
  </div>
</template>
